import ReactMasonry from 'react-masonry-css'

const columns = {
  default: 4,
  1280: 3,
  1024: 2,
  480: 1
}

export const Masonry = ({ items }) => {
  return (
    <ReactMasonry
      breakpointCols={columns}
      className="masonry__text-card-wrapper"
      columnClassName="masonry__text-card-wrapper__column"
    >
      {
        items.map((item, key) => (
          <div className="masonry__text-card" key={`key-levels-${item.title}-${key}`}>
            <h3 className="masonry__text-card__title">{item.title}</h3>
            {/* <p className="masonry__text-card__paragraph">{item.body}</p> */}
          </div>
        ))
      }
    </ReactMasonry>
  )
}