function publishToGTM(value) {
  window.dataLayer.push({
    event: `vh-${value}`
  })
}

export function emailSubmitSuccess() {
  publishToGTM('emailSubmitSuccess')
}

export function emailSubmitFailure() {
  publishToGTM('emailSubmitFailure')
}