import { useState } from 'react'

import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import Collapsible from 'react-collapsible'
import { FaChevronDown } from 'react-icons/fa'
import classnames from 'classnames'
import { Helmet } from 'react-helmet-async'

import { ScreenWrapper } from './wrapper/ScreenWrapper'
import { faqImage } from '../images'
import { XL } from '../components/XL'

const faqItems = [
  {
    title: 'FAQ.Question1',
    body: 'FAQ.Answer1',
  },
  {
    title: 'FAQ.Question2',
    body: 'FAQ.Answer2',
  },
  {
    title: 'FAQ.Question3',
    body: 'FAQ.Answer3',
  },
  {
    title: 'FAQ.Question4',
    body: 'FAQ.Answer4',
  },
  {
    title: 'FAQ.Question5',
    body: 'FAQ.Answer5',
  },
  {
    title: 'FAQ.Question6',
    body: 'FAQ.Answer6',
  },
]

export const FAQ = () => {
  const [isOpen, setIsOpen] = useState(faqItems.map(i => null))

  const onToggle = (idx, value) => {
    setIsOpen(prevIsOpen => prevIsOpen.map((isOpen, index) => index === idx ? value : isOpen))
  }

  const chevronClassNames = (idx) => classnames('screen__faq__questions__chevron', {
    'screen__faq__questions__chevron--open': isOpen[idx],
    'screen__faq__questions__chevron--closed': isOpen[idx] === false,
  })

  return (
    <ScreenWrapper>
      <Helmet><title>Vicky Chatzipetrou | FAQ</title></Helmet>
      <div className="screen-body screen__faq">
        <h1 className="screen-body__main-title"><XL code='MainTitle.QnA' /></h1>
        <div className="screen__faq__image-wrapper">
          <LazyLoadImage visibleByDefault={true} effect='blur' src={faqImage} alt={'English Lessons'} className="screen__faq__image" />
        </div>
        <div className="screen__faq__questions">
          {
            faqItems.map((item, idx) => (
              <Collapsible
                key={`faq-${item.title}`}
                trigger={[
                  <XL code={item.title} key={`faq-title-${item.title}`} />,
                  <FaChevronDown className={chevronClassNames(idx)} key={`faq-icon-${item.title}`} />
                ]}
                onTriggerOpening={() => {onToggle(idx, true)}}
                onTriggerClosing={() => {onToggle(idx, false)}}
              >
                <XL code={item.body} />
              </Collapsible>
            ))
          }
        </div>
      </div>
    </ScreenWrapper>
  )
}