import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Helmet } from 'react-helmet-async'

import { Masonry } from '../components/Masonry'
import { ScreenWrapper } from './wrapper/ScreenWrapper'
import { levelsImage } from '../images'
import { XL } from '../components/XL'

const levelsItems = [
  { title: '1. PRE-JUNIOR' },
  { title: '2. JUNIOR A /JUNIOR B' },
  { title: '3. SENIOR A, B, C' },
  { title: '4. B1, B1+' },
  { title: '5. B2' },
  { title: '6. C1, C2' },
  { title: 'IELTS' },
  { title: 'TOEIC' },
  { title: 'FRENCH - all levels' },
  { title: 'GERMAN - all levels' },
]

export const Levels = () => (
  <ScreenWrapper>
    <Helmet><title>Vicky Chatzipetrou | Levels</title></Helmet>
    <div className="screen-body screen__levels">
      <h1 className="screen-body__main-title"><XL code='MainTitle.Levels' /></h1>
      <div className="screen__levels__image-wrapper">
        <LazyLoadImage visibleByDefault={true} effect='blur' src={levelsImage} alt={'English Lessons'} className="screen__levels__image" />
      </div>
      <Masonry items={levelsItems} />
    </div>
  </ScreenWrapper>
)