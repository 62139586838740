import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { IoIosMail, IoMdPhonePortrait } from 'react-icons/io'
import { Helmet } from 'react-helmet-async'

import { ScreenWrapper } from './wrapper/ScreenWrapper'
import { contactImage } from '../images'
import { CONTACT_EMAIL, CONTACT_PHONE } from '../constants'
import { XL } from '../components/XL'
import { ContactForm } from '../components/ContactForm'

export const Contact = () => (
  <ScreenWrapper>
    <Helmet><title>Vicky Chatzipetrou | Contact</title></Helmet>
    <div className="screen-body screen__contact">
      <h1 className="screen-body__main-title"><XL code='MainTitle.Contact' /></h1>
      <div className="screen__contact__main-area">
        <LazyLoadImage visibleByDefault={true} effect='blur' src={contactImage} alt={'Contact Us'} className="screen__contact__content__image" />
        <div className="screen__contact__contact-item-area-wrapper">
          <div className="screen-body__contact-item-area">
            <h3 className="screen-body__contact-item-area-title"><XL code={'Contact.FormTitle'} /></h3>
            <ContactForm />
          </div>
          <div className="screen-body__contact-item-area">
            <h3 className="screen-body__contact-item-area-title"><XL code={'Contact.ImmediateTitle'} /></h3>
            <div className="screen__contact__contact-item">
              <a className="screen__contact__contact-item__link" href={`tel:${CONTACT_PHONE}`}>
                <IoMdPhonePortrait className="screen__contact__contact-item__icon" />
              </a>
              <a className="screen__contact__contact-item__link" href={`tel:${CONTACT_PHONE}`}>
                <XL code={'Contact.Phone'} />
              </a>
            </div>
            {/* <div className="screen__contact__contact-item">
              <a className="screen__contact__contact-item__link" href='https://www.instagram.com/' target='_blank' rel='noopener noreferrer'>
                <IoLogoInstagram className="screen__contact__contact-item__icon" />
              </a>
              <a className="screen__contact__contact-item__link" href='https://www.instagram.com/' target='_blank' rel='noopener noreferrer'>
                Message us on instagram
              </a>
            </div> */}
            <div className="screen__contact__contact-item">
              <a className="screen__contact__contact-item__link" href={`mailto:${CONTACT_EMAIL}`}>
                <IoIosMail className="screen__contact__contact-item__icon" />
              </a>
              <a className="screen__contact__contact-item__link" href={`mailto:${CONTACT_EMAIL}`}>
                <XL code={'Contact.Email'} />
              </a>
            </div>
            {/* <div className="screen__contact__contact-item">
              <a className="screen__contact__contact-item__link" href={FB_LINK} target='_blank' rel='noopener noreferrer'>
                <IoLogoFacebook className="screen__contact__contact-item__icon" />
              </a>
              <a className="screen__contact__contact-item__link" href={FB_LINK} target='_blank' rel='noopener noreferrer'>
                <XL code={'Contact.FB'} />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  </ScreenWrapper>
)