import { withRouter } from 'react-router-dom'
import { ScreenWrapper } from './wrapper/ScreenWrapper'

export const NotFound = () => {

  const HomepageRedirect = withRouter(({ history }) => (
    <span className="screen__not-found__body__redirect" onClick={() => {history.push('/')}}>Home Page</span>
  ))

  return (
    <ScreenWrapper>
      <div className="screen-body screen__not-found">
        <h1 className="screen-body__main-title">Not Found</h1>
        <div className="screen__not-found__body">
          <div className="screen__not-found__body__404">404</div>
          <span className="screen__not-found__body__text">Sadly the page you are looking for doesn't exist. Try heading back to the </span>
          <HomepageRedirect />
        </div>
      </div>
    </ScreenWrapper>
  )
}