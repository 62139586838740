import { CONTACT_EMAIL } from '../../constants'

export const gr = {
  'LanguageSwitcher.lang.en': 'Αγγλικά',
  'LanguageSwitcher.lang.gr': 'Ελληνικά',

  'TopNavLink.Home': 'Αρχική',
  'TopNavLink.AboutUs': 'Σχετικά με εμάς',
  'TopNavLink.Levels': 'Επίπεδα',
  'TopNavLink.QnA': 'Συχνές Ερωτήσεις',
  'TopNavLink.Contact': 'Επικοινωνία',

  'MainTitle.Home': 'Βίκυ Χατζηπέτρου',
  'MainTitle.AboutUs': 'Σχετικά με εμάς',
  'MainTitle.Levels': 'Επίπεδα',
  'MainTitle.QnA': 'Συχνές Ερωτήσεις',
  'MainTitle.Contact': 'Επικοινωνία',

  'Footer.Title': 'Κέντρο Ξένων Γλωσσών',
  'Footer.Text1': 'Β. Χατζηπέτρου',
  'Footer.Text2': 'Ιαλυσός-Ρόδος',
  'Footer.Text3': 'Μαθήματα ξένης γλώσσας όλων των επιπέδων',
  'Footer.Text4': 'σύμφωνα με τις νέες μεθόδους διδασκαλίας',
  'Footer.Creator': 'vickychatzipetrou.gr © 2022 created by Ioannis Zifos',
  'Footer.TnC': 'Απόρρητο και Προσωπικά Δεδομένα',

  'FAQ.Question1': '1. Σε τι επίπεδο βρίσκομαι;',
  'FAQ.Answer1': 'Κατόπιν επικοινωνίας με τη διεύθυνση θα σας δοθεί ένα τεστ τοποθέτησης που θα σας κατατάξει στο ανάλογο επίπεδο.',
  'FAQ.Question2': '2. Ποιο είναι το κόστος των μαθημάτων;',
  'FAQ.Answer2': 'Το κόστος διαμορφώνεται ανάλογα με το επίπεδο. ',
  'FAQ.Question3': '3. Ποιοι είναι οι φορείς διεξαγωγής εξετάσεων;',
  'FAQ.Answer3': 'Οι πλέον πιστοποιημένοι φορείς. Συγκεκριμένα Cambridge, Michigan, PTE, ESB, Goethe, Institut Français.',
  'FAQ.Question4': '4. Πως πληρώνονται τα μαθήματα;',
  'FAQ.Answer4': 'Με μετρητά, κατάθεση στην τραπεζικό λογαριασμό ή κάρτα.',
  'FAQ.Question5': '5. Πως ενημερώνεται ο κηδεμόνας για την πρόοδο του μαθητή;',
  'FAQ.Answer5': 'Υπάρχει άμεση και συχνή επικοινωνία και ενημέρωση μεταξύ κηδεμόνα και μαθητή. Μπορείτε να κλείσετε ραντεβού και να ενημερωθείτε όποτε επιθυμείτε. Επιπρόσθετα, ανά τακτά χρονικά διαστήματα, υπάρχει μέρα ενημέρωσης προόδου.',
  'FAQ.Question6': '6. Υπάρχουν τμήματα ενηλίκων;',
  'FAQ.Answer6': 'Υπάρχουν ολιγομελή τμήματα ενηλίκων από αρχάριους έως proficiency και δυνατότητα επιλογής διαδικτυακού μαθήματος ή με φυσική παρουσία.',

  'AboutUs.Title1': 'Η Εμπειρία',
  'AboutUs.Title2': 'Τα Μαθήματα',
  'AboutUs.Title3': 'Ο Μαθητής',
  'AboutUs.Paragraph1': 'Η εμπειρία 30 ετών συνδυαστικά με τις νέες τεχνολογίες και το καταρτισμένο προσωπικό συμβάλλει στη διδασκαλία της ξένης γλώσσας με σεβασμό στο μαθητή και τις ιδιαίτερες ανάγκες του.',
  'AboutUs.Paragraph2': 'Τα μαθήματα παρέχονται διαδικτυακά ή δια ζώσης σε ολιγομελή τμήματα όλων των επιπέδων. Ιδιαίτερη προσοχή δίνεται πρωτίστως στην εκμάθηση της γλώσσας και στη συνέχεια, στην πιστοποίηση της μόνο από σοβαρούς φορείς διεξαγωγής εξετάσεων (Cambridge, Michigan, PTE, ESB, Goethe, Institut Français).',
  'AboutUs.Paragraph3': 'Ο μαθητής παρακολουθεί τα μαθήματα σε ένα ευχάριστο, κατάλληλο για μάθηση περιβάλλον, με τη χρήση νέων τεχνολογιών. Οι καθηγητές και η διεύθυνση του κέντρου στηρίζουν το μαθητή στην προσπάθεια του αυτή και συνεργάζονται αρμονικά με τους γονείς και τους κηδεμόνες.',
  'AboutUs.Paragraph4': 'Υπάρχει η δυνατότητα εξ αποστάσεως διδασκαλίας των γλωσσών, σε μια εικονική τάξη με όλα τα μέσα της πραγματικής.',
  'AboutUs.Paragraph5': 'Με εκτίμηση',
  'AboutUs.Paragraph6': 'Βίκυ Χατζηπέτρου',
  'AboutUs.Paragraph7': 'Υποψήφια διδάκτορας Πανεπιστημίου Αιγαίου',

  'Contact.Phone': '(+30)22410-95298',
  'Contact.Email': `${CONTACT_EMAIL}`,
  'Contact.FB': 'facebook',
  'Contact.FormTitle': 'Στείλτε μας το μήνυμά σας',
  'Contact.ImmediateTitle': 'Εναλλακτικά',

  'ContactForm.Name.Label': 'Όνομα',
  'ContactForm.Name.Error': 'Παρακαλώ εισάγετε ένα έγκυρο όνομα',
  'ContactForm.Email.Label': 'Email',
  'ContactForm.Email.Error': 'Παρακαλώ εισάγετε ένα έγκυρο email',
  'ContactForm.Message.Label': 'Μήνυμα',
  'ContactForm.Message.Error': 'Παρακαλώ εισάγετε ένα έγκυρο μήνυμα μέχρι 1000 χαρακτήρες',
  'ContactForm.Submit': 'Αποστολή',
  'ContactForm.Submit.Message.Success': 'Αποστολή Επιτυχής',
  'ContactForm.Submit.Message.Failure': 'Κάτι πήγε στραβά, παρακαλώ προσπαθήστε αργότερα',

  'CookiesBanner.Text': 'Η ιστοσελίδα μας χρησιμοποιεί cookies για να λειτουργήσει και να βελτιώσει την εμπειρία σας. Μπορείτε να διαβάσετε περισσότερες πληροφορίες σχετικά με τη',
  'CookiesBanner.PrivacyPolicyLink': 'Πολιτική Απόρρητου',
  'CookiesBanner.Accept': 'Εντάξει',

  // TODO these need to change
  'PrivacyPolicy.MainTitle': 'Terms of Use',
  'PrivacyPolicy.Title1': 'Intellectual Property',
  'PrivacyPolicy.Paragraph1': 'The content of this website is covered and protected by the relevant legislation, particularly regarding intellectual property and personality rights. Any copy or reproduction the content by any means is strictly forbidden, unless prior and explicit permission is given on behalf of the right holders. All rights reserved.',
  'PrivacyPolicy.Title2': 'Privacy Policy',
  'PrivacyPolicy.Paragraph2': 'Our website uses “Google Analytics” services, which collect anonymised data during your navigation, while not permitting the identification of the user. “Google Analytics” processes the data in order to provide for statistic results regarding the operation of the website, the experience and interaction of the users with it.',
  'PrivacyPolicy.Title3': 'Which type of data is collected and how?',
  'PrivacyPolicy.Paragraph3': '“Google Analytics” uses cookies, namely small text files collecting information, which are stored on your device. “Google Analytics” uses functionality, tracking and performance cookies, for instance relating to your IP address and location, your personalised options during your navigation on the website (e.g. language preferences) and the time of your visit.',
  'PrivacyPolicy.Title4': 'Who processes the data, why and for how long?',
  'PrivacyPolicy.Paragraph4': '“Google Analytics” accesses and processes the data according to its policy and provides for statistics regarding the user interaction with the website and its operation (for instance, the number and the location of users, or popular content on the website). You can find more details on how data is processed by “Google Analytics” and Google <a className="privacy-policy__section__body__link" href="https://policies.google.com/technologies/partner-sites?hl=en" target="_blank" rel="noopener noreferrer">here</a>. I (Vicky Chatzipetrou), as owner and manager of the website, also have access to the data collected, as well as the right to manage, retain or delete it. The purpose of the above actions involving cookies is to enable us to understand the users’ preferences and enhance their experience on the website, by enriching popular content, saving their personalised options, as well as duly observing and fixing operational issues. The data is stored only for the time necessary in order to receive the relevant statistic results in accordance with “Google Analytics” policy, where the maximum retainment times are specified. As soon as these periods expire, we also delete the data without delay.',
  'PrivacyPolicy.Title5': 'Users’ rights',
  'PrivacyPolicy.Paragraph5': 'Users have the right not to give or to withdraw their consent regarding the collection and processing of cookies on our website by “Google Analytics” and us. Withdrawal can take place any time, either by clinking on this link or by choosing not to give your consent the next time you visit our website. In addition, users have the right to ask for more information on the cookies collected on our website and corresponding to their userID or ClientID, as well as the right to access them and request their deletion.',
  'PrivacyPolicy.Title6': 'Google’s privacy policy',
  'PrivacyPolicy.Paragraph6': 'As “Google Analytics’” clients, we comply with the relevant Terms of Use (available <a className="privacy-policy__section__body__link" href="https://marketingplatform.google.com/about/analytics/terms/gb/" target="_blank" rel="noopener noreferrer">here</a>) and with Google’s Privacy Policy (available <a className="privacy-policy__section__body__link" href="https://policies.google.com/privacy?hl=en#about" target="_blank" rel="noopener noreferrer">here</a>). You can find more details on the data collected by Google and its processing, as well as any other relevant information <a className="privacy-policy__section__body__link" href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noopener noreferrer">here</a>. Google reserves the right to modify the above terms and policies. In that case, the amended content is applicable as soon as it enters into force in accordance with Google’s respective terms of use.',
  'PrivacyPolicy.Paragraph7': `For any question regarding the above or for any other information, feel free to contact us at <a className="privacy-policy__section__body__link" href="mailto:${CONTACT_EMAIL}">${CONTACT_EMAIL}</a>`
}