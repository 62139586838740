import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'
import { Contact } from '../screens/Contact'
import { About } from '../screens/About'
import { Home } from '../screens/Home'
import { FAQ } from '../screens/FAQ'
import { Levels } from '../screens/Levels'
import { NotFound } from '../screens/NotFound'

export const Routing = () => (
  <Router>
    <Switch>
      <Route exact path={'/'} component={Home}/>
      <Route exact path={'/about'} component={About} />
      <Route exact path={'/faq'} component={FAQ} />
      <Route exact path={'/levels'} component={Levels} />
      <Route exact path={'/contact'} component={Contact} />
      <Route component={NotFound} />
    </Switch>
  </Router>
)