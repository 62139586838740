import { useState, useEffect } from 'react'
import { TopNav } from '../../components/TopNav'
import { Footer } from '../../components/Footer'
import { CookiesBanner } from '../../components/CookiesBanner'
import { PrivacyPolicy } from '../../components/PrivacyPolicy'
// import { handleGTMScripts } from '../../util'
import { ACCEPT_COOKIES_STORAGE_NAME } from '../../constants'

export const ScreenWrapper = (props) => {

  const [isPrivacyPolicyOpen, setIsPrivacyPolicyOpen] = useState(false)
  const [isCookiesBannerVisible, setIsCookiesBannerVisible] = useState(true)

  useEffect(() => {
    const hasAcceptedCookies = localStorage.getItem(ACCEPT_COOKIES_STORAGE_NAME)

    // this is a string, so here we'll do the same in both cases
    if (hasAcceptedCookies) {
      setIsCookiesBannerVisible(false)
    }
  }, [])

  const privacyPolicyClickHandler = () => {
    setIsPrivacyPolicyOpen(true)
  }

  const acceptCookiesHandler = () => {
    // handleGTMScripts()

    localStorage.setItem(ACCEPT_COOKIES_STORAGE_NAME, true)
    setIsCookiesBannerVisible(false)
    setIsPrivacyPolicyOpen(false)
  }

  // const declineCookiesHandler = () => {
  //   localStorage.setItem(ACCEPT_COOKIES_STORAGE_NAME, false)
  //   setIsCookiesBannerVisible(false)
  // }

  return (
    <div className="screen">
      <TopNav />
      {props.children}
      <Footer privacyPolicyClickHandler={privacyPolicyClickHandler} />
      {
        isPrivacyPolicyOpen &&
        <PrivacyPolicy isOpen={isPrivacyPolicyOpen} onCloseHandler={() => setIsPrivacyPolicyOpen(false)} acceptCookiesHandler={acceptCookiesHandler} />
      }
      {
        !isPrivacyPolicyOpen && isCookiesBannerVisible &&
        <CookiesBanner privacyPolicyClickHandler={privacyPolicyClickHandler} acceptCookiesHandler={acceptCookiesHandler} />
      }
    </div>
  )
}