import Modal from 'react-modal'
import { IoMdClose } from 'react-icons/io'

import { XL } from './XL'

export const PrivacyPolicy = ({
  isOpen,
  onCloseHandler,
  acceptCookiesHandler,
  // declineCookiesHandler
}) => (
  <Modal isOpen={isOpen} ariaHideApp={false}>

    <div className="privacy-policy">

      <button className="privacy-policy__close">
        <IoMdClose className="privacy-policy__close__icon" onClick={onCloseHandler}/>
      </button>

      <h1  className="privacy-policy__title"><XL code='PrivacyPolicy.MainTitle' /></h1>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title1' /></h2>
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph1' />
        </div>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title2' /></h2>
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph2' />
        </div>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title3' /></h2> 
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph3' />
        </div>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title4' /></h2>
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph4' isHTML />
        </div>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title5' /></h2>
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph5' />
        </div>
      </div>

      <div className="privacy-policy__section">
        <h2 className="privacy-policy__section__title"><XL code='PrivacyPolicy.Title6' /></h2>
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph6' isHTML />
        </div>
      </div>

      <div className="privacy-policy__section">
        <div className="privacy-policy__section__body">
          <XL code='PrivacyPolicy.Paragraph7' isHTML />
        </div>
      </div>

      <div className="privacy-policy__actions">
        {/* <button className="privacy-policy__actions__decline" onClick={declineCookiesHandler}>Decline</button> */}
        <button className="privacy-policy__actions__accept" onClick={acceptCookiesHandler}>Ok</button>
      </div>
    </div>
  </Modal>
)