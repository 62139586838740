import { NavLink } from 'react-router-dom'

// tried to use `as` prop but had issues with warnings in the console, so switched back
// not the best approach I could come up with but it was a quick one
export const TopNavLink = ({ isSimple, exact, activeClassName, to, children }) => {
  return isSimple ? (
    <div className="top-nav-link top-nav-link-simple">
      {children}
    </div>
  ) : (
    <NavLink className="top-nav-link" exact={exact} to={to} activeClassName={activeClassName}>
      {children}
    </NavLink>
  )
}