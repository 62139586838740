import { useState } from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import Box from '@material-ui/core/Box'
import { ToastContainer, toast } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import { XL } from './XL'
import { useClickOutAndIn } from '../hooks/useClickOutAndIn'
import { emailSubmitSuccess, emailSubmitFailure } from '../analytics'

const googleAppScriptUrl = 'https://script.google.com/macros/s/AKfycbyC-TPY5zMeCszxhjJozT8uk67LMnXkxLLyPp-tARRN5giUn6Ur4n_5Dc33nUNwml3e/exec'

const sendEmail = async (name, email, message) => {
  await fetch(
    `${googleAppScriptUrl}?name=${name}&email=${email}&message=${message}`,
    {
      mode: 'no-cors'
    }
  )
}

const fieldRegex = {
  name: /^.{1,1000}$/,
  email: /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  message: /^.{1,1000}$/,
}

const initialState = {
  value: '',
  isValid: false,
  isTouched: false,
  isActive: false,
}

const validateField = (value, setItem, regex) => {
  if ((!value) || (regex && !regex.test(value))) {
    setItem(prevItem => ({ ...prevItem, isValid: false }))
  } else {
    setItem(prevItem => ({ ...prevItem, isValid: true }))
  }
}

const onChange = (value, setItem, regex) => {
  setItem(prevItem => ({ ...prevItem, value }))
  validateField(value, setItem, regex)
}

const onClick = (setItem) => {
  setItem(prevItem => ({ ...prevItem, isTouched: true, isActive: true }))
}

const onClickOutside = (setItem) => {
  setItem(prevItem => ({ ...prevItem, isActive: false }))
}

export const ContactForm = () => {
  const [name, setName] = useState(initialState)
  const [email, setEmail] = useState(initialState)
  const [message, setMessage] = useState(initialState)

  const nameRef = useClickOutAndIn({ onClick: () => {onClickOutside(setName)}, isRefContains: false })
  const emailRef = useClickOutAndIn({ onClick: () => {onClickOutside(setEmail)}, isRefContains: false })
  const messageRef = useClickOutAndIn({ onClick: () => {onClickOutside(setMessage)}, isRefContains: false })

  const isSubmitEnabled = name.isValid && email.isValid && message.isValid

  const clearFields = () => {
    setName(initialState)
    setEmail(initialState)
    setMessage(initialState)
  }

  const onSubmit = async (e) => {
    e.preventDefault()

    if (!isSubmitEnabled) {
      return
    }

    try {
      sendEmail(name.value, email.value, message.value)
      clearFields()
      toast.success(<XL code='ContactForm.Submit.Message.Success' />)
      emailSubmitSuccess()
    } catch (e) {
      toast.error(<XL code='ContactForm.Submit.Message.Failure' />)
      emailSubmitFailure()
    }
  }

  return (
    <>
      <form className="contact-form">
        <TextField
          ref={nameRef}
          value={name.value}
          onChange={(e) => {onChange(e.target.value, setName, fieldRegex.name)}}
          onBlur={() => {validateField(name.value, setName, fieldRegex.name)}}
          label={<XL code='ContactForm.Name.Label' />}
          name={'name'}
          required
          error={!name.isValid && name.isTouched && !name.isActive}
          helperText={name.isValid || !name.isTouched || name.isActive ? undefined : <XL code='ContactForm.Name.Error' />}
          onClick={() => {onClick(setName)}}
          fullWidth
        />
        <TextField
          ref={emailRef}
          value={email.value}
          onChange={(e) => {onChange(e.target.value, setEmail, fieldRegex.email)}}
          onBlur={() => {validateField(email.value, setEmail, fieldRegex.email)}}
          label={<XL code='ContactForm.Email.Label' />}
          name={'email'}
          required
          error={!email.isValid && email.isTouched && !email.isActive}
          helperText={email.isValid || !email.isTouched || email.isActive ? undefined : <XL code='ContactForm.Email.Error' />}
          onClick={() => {onClick(setEmail)}}
          fullWidth
        />
        <TextField
          ref={messageRef}
          value={message.value}
          onChange={(e) => {onChange(e.target.value, setMessage, fieldRegex.message)}}
          onBlur={() => {validateField(message.value, setMessage, fieldRegex.message)}}
          label={<XL code='ContactForm.Message.Label' />}
          name={'message'}
          required
          multiline
          rows={3}
          error={!message.isValid && message.isTouched && !message.isActive}
          helperText={message.isValid || !message.isTouched || message.isActive ? undefined : <XL code='ContactForm.Message.Error' />}
          onClick={() => {onClick(setMessage)}}
          fullWidth
        />
        <Box mt={2}>
          <Button type='submit' disabled={!isSubmitEnabled} onClick={onSubmit}>
            <XL code='ContactForm.Submit' />
          </Button>
        </Box>
      </form>
      <ToastContainer />
    </>
  )
}