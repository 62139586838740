import { XL } from './XL'

export const CookiesBanner = ({ privacyPolicyClickHandler, acceptCookiesHandler }) => (
  <div className="cookies-banner">
    <div className="cookies-banner__text">
      <XL code='CookiesBanner.Text' />{' '}
      <span className="cookies-banner__text__link" onClick={privacyPolicyClickHandler}><XL code='CookiesBanner.PrivacyPolicyLink' /></span>
    </div>
    <div className="cookies-banner__actions">
      {/* <button className="cookies-banner__actions__decline" onClick={declineCookiesHandler}>Decline</button> */}
      <button className="cookies-banner__actions__accept" onClick={acceptCookiesHandler}><XL code='CookiesBanner.Accept' /></button>
    </div>
  </div>
)