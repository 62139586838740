import { CONTACT_EMAIL } from '../../constants'

export const en = {
  'LanguageSwitcher.lang.en': 'English',
  'LanguageSwitcher.lang.gr': 'Greek',

  'TopNavLink.Home': 'Home',
  'TopNavLink.AboutUs': 'About Us',
  'TopNavLink.Levels': 'Levels',
  'TopNavLink.QnA': 'Q & A',
  'TopNavLink.Contact': 'Contact',

  'MainTitle.Home': 'Vicky Chatzipetrou',
  'MainTitle.AboutUs': 'About us',
  'MainTitle.Levels': 'Levels',
  'MainTitle.QnA': 'Freqently Asked Questions',
  'MainTitle.Contact': 'Contact',

  'Footer.Title': '',
  'Footer.Text1': '',
  'Footer.Text2': '',
  'Footer.Text3': '',
  'Footer.Text4': '',
  'Footer.Creator': 'vickychatzipetrou.gr © 2022 created by Ioannis Zifos',
  'Footer.TnC': 'Privacy - Terms and Conditions',

  'FAQ.Question1': '',
  'FAQ.Answer1': '',
  'FAQ.Question2': '',
  'FAQ.Answer2': '',
  'FAQ.Question3': '',
  'FAQ.Answer3': '',
  'FAQ.Question4': '',
  'FAQ.Answer4': '',
  'FAQ.Question5': '',
  'FAQ.Answer5': '',
  'FAQ.Question6': '',
  'FAQ.Answer6': '',

  'AboutUs.Title1': '',
  'AboutUs.Title2': '',
  'AboutUs.Title3': '',
  'AboutUs.Paragraph1': '',
  'AboutUs.Paragraph2': '',
  'AboutUs.Paragraph3': '',
  'AboutUs.Paragraph4': '',
  'AboutUs.Paragraph5': '',
  'AboutUs.Paragraph6': '',
  'AboutUs.Paragraph7': '',

  'Contact.Phone': '(+30)22410-95298',
  'Contact.Email': `${CONTACT_EMAIL}`,
  'Contact.FB': 'facebook',
  'Contact.FormTitle': 'Send us your message',
  'Contact.ImmediateTitle': 'Alternatively',

  'ContactForm.Name.Label': 'Name',
  'ContactForm.Name.Error': 'Please provide a valid name',
  'ContactForm.Email.Label': 'Email',
  'ContactForm.Email.Error': 'Please provide a valid email',
  'ContactForm.Message.Label': 'Message',
  'ContactForm.Message.Error': 'Please provide a valid message up to 1000 characters',
  'ContactForm.Submit': 'Submit',
  'ContactForm.Submit.Message.Success': 'Submission Successul',
  'ContactForm.Submit.Message.Failure': 'An error has occurred, please try again later',

  'CookiesBanner.Text': 'Our website uses cookies to improve its functionality and your user experience. You can find more information by reading our',
  'CookiesBanner.PrivacyPolicyLink': 'Privacy Policy',
  'CookiesBanner.Accept': 'Ok',

  'PrivacyPolicy.MainTitle': 'Terms of Use',
  'PrivacyPolicy.Title1': 'Intellectual Property',
  'PrivacyPolicy.Paragraph1': 'The content of this website is covered and protected by the relevant legislation, particularly regarding intellectual property and personality rights. Any copy or reproduction the content by any means is strictly forbidden, unless prior and explicit permission is given on behalf of the right holders. All rights reserved.',
  'PrivacyPolicy.Title2': 'Privacy Policy',
  'PrivacyPolicy.Paragraph2': 'Our website uses “Google Analytics” services, which collect anonymised data during your navigation, while not permitting the identification of the user. “Google Analytics” processes the data in order to provide for statistic results regarding the operation of the website, the experience and interaction of the users with it.',
  'PrivacyPolicy.Title3': 'Which type of data is collected and how?',
  'PrivacyPolicy.Paragraph3': '“Google Analytics” uses cookies, namely small text files collecting information, which are stored on your device. “Google Analytics” uses functionality, tracking and performance cookies, for instance relating to your IP address and location, your personalised options during your navigation on the website (e.g. language preferences) and the time of your visit.',
  'PrivacyPolicy.Title4': 'Who processes the data, why and for how long?',
  'PrivacyPolicy.Paragraph4': '“Google Analytics” accesses and processes the data according to its policy and provides for statistics regarding the user interaction with the website and its operation (for instance, the number and the location of users, or popular content on the website). You can find more details on how data is processed by “Google Analytics” and Google <a className="privacy-policy__section__body__link" href="https://policies.google.com/technologies/partner-sites?hl=en" target="_blank" rel="noopener noreferrer">here</a>. I (Vicky Chatzipetrou), as owner and manager of the website, also have access to the data collected, as well as the right to manage, retain or delete it. The purpose of the above actions involving cookies is to enable us to understand the users’ preferences and enhance their experience on the website, by enriching popular content, saving their personalised options, as well as duly observing and fixing operational issues. The data is stored only for the time necessary in order to receive the relevant statistic results in accordance with “Google Analytics” policy, where the maximum retainment times are specified. As soon as these periods expire, we also delete the data without delay.',
  'PrivacyPolicy.Title5': 'Users’ rights',
  'PrivacyPolicy.Paragraph5': 'Users have the right not to give or to withdraw their consent regarding the collection and processing of cookies on our website by “Google Analytics” and us. Withdrawal can take place any time, either by clinking on this link or by choosing not to give your consent the next time you visit our website. In addition, users have the right to ask for more information on the cookies collected on our website and corresponding to their userID or ClientID, as well as the right to access them and request their deletion.',
  'PrivacyPolicy.Title6': 'Google’s privacy policy',
  'PrivacyPolicy.Paragraph6': 'As “Google Analytics’” clients, we comply with the relevant Terms of Use (available <a className="privacy-policy__section__body__link" href="https://marketingplatform.google.com/about/analytics/terms/gb/" target="_blank" rel="noopener noreferrer">here</a>) and with Google’s Privacy Policy (available <a className="privacy-policy__section__body__link" href="https://policies.google.com/privacy?hl=en#about" target="_blank" rel="noopener noreferrer">here</a>). You can find more details on the data collected by Google and its processing, as well as any other relevant information <a className="privacy-policy__section__body__link" href="https://support.google.com/analytics/answer/6004245" target="_blank" rel="noopener noreferrer">here</a>. Google reserves the right to modify the above terms and policies. In that case, the amended content is applicable as soon as it enters into force in accordance with Google’s respective terms of use.',
  'PrivacyPolicy.Paragraph7': `For any question regarding the above or for any other information, feel free to contact us at <a className="privacy-policy__section__body__link" href="mailto:${CONTACT_EMAIL}">${CONTACT_EMAIL}</a>`
}