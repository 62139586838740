import { useEffect } from 'react'

import { HelmetProvider } from 'react-helmet-async'

import { Routing } from './routing'
import AllContextsProvider from './context'

import { aboutImage, contactImage, faqImage, homeImage, levelsImage } from './images'

const App = () => {
  // big hack to preload all images, this puts in perspective
  // the need for lazy loading images in each component
  // possible alternative to put images in the window object and get them from there
  useEffect(() => {
    const preloadImages = [aboutImage, contactImage, faqImage, homeImage, levelsImage]

    preloadImages.forEach(image => {
      new Image().src = image
    })
  }, [])

  return (
    <div>
      <HelmetProvider>
        <AllContextsProvider>
          <Routing />
        </AllContextsProvider>
      </HelmetProvider>
    </div>
  )
}

export default App
