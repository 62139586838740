import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-lazy-load-image-component/src/effects/blur.css'
import { Helmet } from 'react-helmet-async'

import { ScreenWrapper } from './wrapper/ScreenWrapper'
import { aboutImage } from '../images'
import { XL } from '../components/XL'

export const About = () => (
  <ScreenWrapper>
    <Helmet><title>Vicky Chatzipetrou | About</title></Helmet>
    <div className="screen-body screen__about">
      <h1 className="screen-body__main-title"><XL code={'MainTitle.AboutUs'} /></h1>
      <div className="screen__about__content">
        <LazyLoadImage visibleByDefault={true} effect='blur' src={aboutImage} alt={'English Lessons'} className="screen__about__content__image" />
        <div className="screen__about__content__text-area">
          <p className="screen__about__title">
            <XL code='AboutUs.Title1' />
          </p>
          <p className="screen__about__paragraph">
            <XL code='AboutUs.Paragraph1' />
          </p>
          <p className="screen__about__title">
            <XL code='AboutUs.Title2' />
          </p>
          <p className="screen__about__paragraph">
            <XL code='AboutUs.Paragraph2' />
          </p>
          <p className="screen__about__title">
            <XL code='AboutUs.Title3' />
          </p>
          <p className="screen__about__paragraph">
            <XL code='AboutUs.Paragraph3' />
          </p>
          <p className="screen__about__paragraph">
            <XL code='AboutUs.Paragraph4' />
          </p>
          <p className="screen__about__subparagraph">
            <XL code='AboutUs.Paragraph5' />
          </p>
          <p className="screen__about__subparagraph">
            <XL code='AboutUs.Paragraph6' />
          </p>
          <p className="screen__about__subparagraph">
            <XL code='AboutUs.Paragraph7' />
          </p>
        </div>
      </div>
    </div>
  </ScreenWrapper>
)