import { useRef, useEffect } from 'react'

export const useClickOutAndIn = ({ onClick, isRefContains = true }) => {
  const ref = useRef(null)

  useEffect(() => {
    // Bind the event listener
    document.addEventListener("mousedown", handleClick)
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClick)
    }
  }, [])

  const handleClick = (event) => {
    if (
      ref.current &&
      (
        (isRefContains && ref.current.contains(event.target)) ||
        (!isRefContains && !ref.current.contains(event.target))
      )
    ) {
      onClick()
    }
  }

  return ref
}