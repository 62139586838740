import { useContext } from 'react'

import { LANG, CHOSEN_LANG_STORAGE_NAME } from '../constants'
import { PreferencesContext } from '../context'
import { XL } from './XL'

// currently working only for 2 languages, will need rework if I need more
export const LanguageSwitcher = () => {
  const { preferences, setPreferences } = useContext(PreferencesContext)

  const onLangClick = () => {
    const lang = preferences[CHOSEN_LANG_STORAGE_NAME] === LANG.GR ? LANG.EN : LANG.GR

    setPreferences(prevPreferences => ({
      ...prevPreferences,
      [CHOSEN_LANG_STORAGE_NAME]: lang
    }))
    localStorage.setItem(CHOSEN_LANG_STORAGE_NAME, lang)
  }

  return (
    <div className="language-switcher">
      <span onClick={onLangClick}>
        <XL code={`LanguageSwitcher.lang.${preferences[CHOSEN_LANG_STORAGE_NAME]}`} />
      </span>
    </div>
  )
}