export const ACCEPT_COOKIES_STORAGE_NAME = 'acceptCookies'
export const CHOSEN_LANG_STORAGE_NAME = 'chosenLang'

export const LANG = {
  EN: 'en',
  GR: 'gr'
}

export const DEFAULT_LANG = LANG.GR

// export const FB_LINK = 'https://www.facebook.com/vicky.hatzipetrou'
export const CONTACT_EMAIL = 'vickychatzipetrou@gmail.com'
export const CONTACT_PHONE = '+302241095298'