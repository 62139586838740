import { createContext, useState } from 'react'

import { CHOSEN_LANG_STORAGE_NAME, DEFAULT_LANG } from '../constants'

const initialPreferences = {
  [CHOSEN_LANG_STORAGE_NAME]: localStorage.getItem(CHOSEN_LANG_STORAGE_NAME) || DEFAULT_LANG,
}

export const PreferencesContext = createContext({
  preferences: initialPreferences,
  setPreferences: () => {}
})

const PreferencesContextProvider = ({ children }) => {
  const [preferences, setPreferences] = useState(initialPreferences)

  return (
    <PreferencesContext.Provider value={{ preferences, setPreferences }}>
      {children}
    </PreferencesContext.Provider>
  )
}

export default PreferencesContextProvider