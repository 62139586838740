import { IoIosMail, IoMdPhonePortrait } from 'react-icons/io'

import { CONTACT_EMAIL, CONTACT_PHONE } from '../constants'
import { XL } from './XL'
// TODO links and details
export const Footer = ({ privacyPolicyClickHandler }) => (
  <footer className="footer">
    {/* logo */}
    <div className="footer__content">
      <h2 className="footer__content__title"><XL code='Footer.Title' /></h2>
      <div className="footer__content__text"><XL code='Footer.Text1' /></div>
      <div className="footer__content__text"><XL code='Footer.Text2' /></div>
      <div className="footer__content__text"><XL code='Footer.Text3' /></div>
      <div className="footer__content__text"><XL code='Footer.Text4' /></div>
      <div className="footer__content__links">
        {/* <a className="footer__content__link" href='https://www.instagram.com/fotislindia.tango/' target='_blank' rel='noopener noreferrer'>
          <IoLogoInstagram className="footer__content__link__img" />
        </a> */}
        <a className="footer__content__link" href={`tel:${CONTACT_PHONE}`}>
          <IoMdPhonePortrait className="footer__content__link__img" />
        </a>
        <a className="footer__content__link" href={`mailto:${CONTACT_EMAIL}`}>
          <IoIosMail className="footer__content__link__img" />
        </a>
        {/* <a className="footer__content__link" href={FB_LINK} target='_blank' rel='noopener noreferrer'>
          <IoLogoFacebook className="footer__content__link__img" />
        </a> */}
      </div>
      <p className="footer__content__creator"><XL code='Footer.Creator' /></p>
      <p className="footer__content__terms" onClick={privacyPolicyClickHandler}><XL code='Footer.TnC' /></p>
    </div>
  </footer>
)