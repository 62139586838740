import { useState } from 'react'
import classnames from 'classnames'
import { IoIosMenu, IoMdClose } from 'react-icons/io'

import { LanguageSwitcher } from './LanguageSwitcher'
import { TopNavLink } from './TopNavLink'
import { XL } from './XL'
import { useClickOutAndIn } from '../hooks/useClickOutAndIn'

export const TopNav = () => {
  // will be used for mobile
  const [isOpen, toggleIsOpen] = useState(false)

  const emptyBgRef = useClickOutAndIn({ onClick: () => {toggleIsOpen(false)} })

  const burgerCallback = () => toggleIsOpen(!isOpen)

  const linksAreaClassNames = classnames('top-nav__links', {
    'top-nav__links--open': isOpen,
    'top-nav__links--closed': !isOpen,
  })

  return (
    <div className="top-nav">
      {
        !isOpen &&
        <div className="top-nav__burger-area">
          <IoIosMenu className="top-nav__burger-icon"  onClick={burgerCallback} />
        </div>
      }
      {
        isOpen &&
        <div className="top-nav__empty-bg" ref={emptyBgRef} />
      }
      <nav className={linksAreaClassNames}>
        {/* <TopNavLink isSimple>
          <LanguageSwitcher />
        </TopNavLink> */}
        {/* Temp until the above is ready */}
        <TopNavLink exact to='/' activeClassName="top-nav-link--active">
          Βίκυ Χατζηπέτρου
        </TopNavLink>
        {
          isOpen &&
          <div className="top-nav__links__close-menu-area">
            <IoMdClose className="top-nav__links__close-menu-icon" onClick={burgerCallback}/>
          </div>
        }
        <div className="top-nav-link-wrapper">
          {/* <TopNavLink exact to='/' activeClassName="top-nav-link--active">
            <XL code='TopNavLink.Home' />
          </TopNavLink> */}
          <TopNavLink exact to='/about' activeClassName="top-nav-link--active">
            <XL code='TopNavLink.AboutUs' />
          </TopNavLink>
          <TopNavLink exact to='/levels' activeClassName="top-nav-link--active">
            <XL code='TopNavLink.Levels' />
          </TopNavLink>
          <TopNavLink exact to='/faq' activeClassName="top-nav-link--active">
            <XL code='TopNavLink.QnA' />
          </TopNavLink>
          <TopNavLink exact to='/contact' activeClassName="top-nav-link--active">
            <XL code='TopNavLink.Contact' />
          </TopNavLink>
        </div>
      </nav>
    </div>
  )
}