import React, { useContext } from 'react'

import { CHOSEN_LANG_STORAGE_NAME } from '../constants'
import { PreferencesContext } from '../context'
import codes from '../translations'

export const XL = ({ code, isHTML, as: Component }) => {
  const { preferences } = useContext(PreferencesContext)
  const activeLang = preferences[CHOSEN_LANG_STORAGE_NAME]

  // if the xlated message doesn't exist then return simply the code
  const finalMessage = codes[activeLang][code] || code

  return (
    isHTML ? (
      <Component dangerouslySetInnerHTML={{ __html: finalMessage }} />
    ) : (
      <>
        {finalMessage}
      </>
    )
  )
}

XL.defaultProps = {
  as: 'div'
}